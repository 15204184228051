<template>
  <div class="form p-2 mb-3 shadow-sm bg-white rounded">
    <h3 class="scoped-form-title py-1 text-primary">{{ props.title }}</h3>
    <!--
    <div v-if="isFormValid" class="text-success">I am valid!</div>
    <div v-else class="text-danger">Not valid</div>
    -->
    <div
      v-for="(field, fieldIndex) in formFields"
      :key="fieldIndex"
      class="form-group m-0 mb-3"
      :class="{ 'd-none': !dependenciesActive(field) }"
    >
      <div v-if="shouldShowField(field)">
        <div v-for="r in repeatTimes(field)" :key="r" class="row">
          <div v-if="!isHidden(field.type)" class="col-4 mb-3">
            <label :for="field.id"
              >{{ dynamicLabels[getVModelId(field)] }}
              <span v-if="field.repeat">[{{ r }}]</span>
              <span v-if="!field.optional">*</span>
              <span v-if="field.intema">
                <img
                  :src="intemaLogoPath"
                  style="max-height: 20px; margin-left: 5px"
                  title="Values entered in this field are used for INTEMA simulations, where appropriate."
                />
              </span>
            </label>
          </div>

          <div class="col-7">
            <input
              v-if="field.type === 'hidden'"
              type="hidden"
              class="form-control"
              :id="fullInputName(field)"
              :name="isModelAttribute(field) ? fullInputName(field) : null"
              v-model="formData[getVModelId(field)]"
            />

            <input
              v-if="field.type === 'integer'"
              type="number"
              class="form-control"
              :id="fullInputName(field, r)"
              :name="fullInputName(field, r)"
              v-model="formData[getVModelId(field, r)]"
              :placeholder="field.description"
              :min="field.range.min"
              :max="field.range.max"
              required
            />

            <input
              v-if="field.type === 'real'"
              type="number"
              class="form-control"
              :id="fullInputName(field, r)"
              :name="fullInputName(field, r)"
              v-model="formData[getVModelId(field, r)]"
              :placeholder="field.description"
              :min="field.range.min"
              :max="field.range.max"
              step="any"
              :disabled="lockedInputs[field.id] === true"
              required
            />

            <input
              v-if="field.type === 'string'"
              class="form-control"
              :type="field.type"
              :id="fullInputName(field, r)"
              :name="fullInputName(field, r)"
              v-model="formData[getVModelId(field, r)]"
              :placeholder="field.description"
              required
            />

            <textarea
              v-if="field.type === 'text'"
              class="form-control"
              :id="fullInputName(field, r)"
              :name="fullInputName(field, r)"
              v-model="formData[getVModelId(field, r)]"
              :placeholder="field.description"
            ></textarea>

            <input
              v-if="field.type === 'file'"
              type="file"
              class="form-control"
              :id="fullInputName(field, r, '_')"
              :name="fullInputName(field, r)"
            />

            <select
              @change="errorsList = []"
              v-if="field.type === 'multipleChoice'"
              class="form-control"
              :id="fullInputName(field, r)"
              :name="
                isModelAttribute(field, r) ? fullInputName(field, r) : null
              "
              v-model="formData[getVModelId(field, r)]"
              :disabled="lockedInputs[field.id] === true"
              required
            >
              <option value="" disabled="disabled" selected="selected">
                Select an option
              </option>
              <option
                v-for="(option, optIndex) in field.optgroup ? field.optgroup : field.options"
                :key="optIndex"
                :value="option['value']"
                :data-filter="option.filter"
              >
                {{ option["text"] }}
              </option>
            </select>

            <div v-if="field.type === 'radio'" :id="fullInputName(field)">
              <label
                v-for="(option, optIndex) in field.options"
                :key="optIndex"
              >
                <input
                  type="radio"
                  :value="option"
                  v-model="formData[getVModelId(field)]"
                  :name="fullInputName(field)"
                  required
                />
                {{ option }}
              </label>
            </div>

            <input
              v-if="field.type === 'date'"
              class="form-control"
              :type="field.type"
              :id="fullInputName(field, r)"
              :name="fullInputName(field, r)"
              v-model="formData[getVModelId(field, r)]"
              :placeholder="field.description"
              required
            />

            <span
              v-if="!isHidden(field.type) && field.range"
              class="range-column ml-1 mr-2"
            >
              <span v-if="field.type === 'integer' || field.type === 'real'">
                (<span>Min: {{ field.range.min }}</span
                ><span v-if="field.range.max">- Max: {{ field.range.max }}</span
                >)
              </span>
            </span>

            <div
              v-if="
                field.type === 'checkboxes' &&
                formData[getVModelId(field)] !== null
              "
            >
              <label v-for="(option, index) in field.options" :key="index">
                <input
                  type="checkbox"
                  :value="true"
                  v-model="formData[getVModelId(field)][option.value]"
                  :id="`${fullInputName(field, r)}[${option.value}]`"
                  :name="`${fullInputName(field, r)}[${option.value}]`"
                  @click="emitEvent(option)"
                />

                {{ option.text }}
              </label>
            </div>

            <span v-if="hasInvalidValue(field, r)" class="text-danger small"
              >Invalid value</span
            >

            <div v-if="hasErrors(getVModelId(field, r))" class="error-column">
              <small class="text-danger">{{
                dbObject.errors[getVModelId(field, r)].join(", ")
              }}</small>
            </div>
          </div>

          <div v-if="!isHidden(field.type)" class="tooltips-column">
            <span
              :key="fieldIndex"
              class="tooltips"
              :data-instructions="field.instructions"
              style="margin-left: 5px"
            >
              <i
                class="fa fa-question-circle-o"
                style="font-size: 20px"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </div>
        <h2 v-if="field.separator"><span>{{ field.separator }}</span></h2>
      </div>
    </div>
    <!--    <ul>-->
    <!--      <li class="text-danger" v-for="(error, index) in errorsList" :key="index" >-->
    <!--          {{ error }}-->
    <!--      </li>-->
    <!--    </ul>-->
    <slot></slot>
  </div>
</template>

<script setup>
import {computed, onBeforeMount, ref, watch,} from "vue";
const emit = defineEmits(["valid"]);
const imagePath = require.context("../../../assets/images");
const intemaLogoPath = imagePath("./intema_logo.png");

const props = defineProps({
  title: String,
  formFields: {
    type: Array,
    required: true,
  },
  prefix: {
    type: String,
    required: false,
    default: "",
  },
  dbObject: {
    type: Object,
    required: true,
  },
  isDistrict: {
    type: Boolean,
  },
});

onBeforeMount(() => {
  initializeCheckboxValues();
});

const isDistrict = props.isDistrict;
const formFields = props.formFields;
const dbObject = props.dbObject;
const formData = ref(dbObject);
const lockedInputs = ref({})
fillMissingAttributesToFormData();
fillDefaultValues();

const fieldGroups = ref([]);

// Populate `fieldGroups` based on the provided `formFields` prop
for (const field of props.formFields) {
  const groupIndex = fieldGroups.value.findIndex(
    (group) => group.title === field.group
  );
  if (groupIndex === -1) {
    fieldGroups.value.push({
      title: field.group,
      show: false,
      fields: [field],
    });
  } else {
    fieldGroups.value[groupIndex].fields.push(field);
  }
}

// Function to toggle collapse of a group
const toggleCollapse = (index) => {
  fieldGroups.value[index].show = !fieldGroups.value[index].show;
};

function shouldShowField(field) {
  // field.showInDistrict = true, isDistrict = true
  if (field.showInDistrict && isDistrict) {
    return true;
  } else {
    //isDistrict = false
    if (field.showInDistrict === true) {
      return false; // do not show the field because you are not in a district
    } else {
      if (field.showInDistrict === false && isDistrict) {
        return false;
      } else {
        return true; // do not show the field because you are neither in a district nor in it should be shown
      }
    }
  }
}

function fillDefaultValues() {
  for (let k in formData.value) {
    const value = formData.value[k];
    const formField = _getFormFieldById(k);
    if (value === null && formField && formField.default !== undefined) {
      formData.value[k] = formField.default;
    }
  }
}

function initializeCheckboxValues() {
  const checkboxFields = formFields.filter((e) => e.type === "checkboxes");
  for (let i in checkboxFields) {
    const field = checkboxFields[i];
    if (!formData.value[getVModelId(field)]) {
      formData.value[getVModelId(field)] = {};
      field.options.forEach((option) => {
        formData.value[getVModelId(field)][option.value] = false;
      });
    }
  }
}

function fillMissingAttributesToFormData() {
  // Some attributes in the forms.yml are not included in the model of Rails.
  // For example Insulation does not have a field `type`. So, its value is not passed into form data when we
  // pass an existing database object. But some other fields might depend on that missing field, so we add it
  // in form data with this function.
  formFields.forEach((formField) => {
    if (!formData.value.hasOwnProperty(formField.id)) {
      formData.value[formField.id] = null;
    }
  });
}

function isModelAttribute(field) {
  if (field.hasOwnProperty("model")) return field.model;
  return true;
}

function fullInputName(field, index = 1, separator = "[]") {
  // TODO: I saw that Rails uses ids in the format of "model_attribute" when we use "model[attribute]".
  //  This is fine for all of the inputs except the file input. I want to change it for all inputs, but I don't
  //  remember if any methods rely on the id (even though I think it does not). So I am creating a special case for
  //  files right now. But in the future we should consider changing every id.
  const attribute = field.id;
  let nested = field.nested;
  if (nested && !Array.isArray(nested)) {
    nested = [nested];
  }
  let result = "";
  if (props.prefix.length === 0) {
    result = attribute;
  } else {
    if (separator === "[]") result = `${props.prefix}[${attribute}]`;
    else if (separator === "_") result = `${props.prefix}_${attribute}`;
  }

  if (nested) {
    if (field.repeat) {
      nested = nestedWithRepeatIndex(nested, index);
    }

    if (separator === "[]") {
      return `${result}[${nested.join("][")}]`;
    } else if (separator === "_") {
      return `${result}_${nested.join("_")}`;
    }
  } else return result;
}

function getVModelId(field, index = 1) {
  const fieldId = field.id;
  let nested = field.nested;
  if (nested && !Array.isArray(nested)) {
    nested = [nested];
  }

  if (nested) {
    if (field.repeat) {
      nested = nestedWithRepeatIndex(nested, index);
    }
    return `${fieldId}_${nested.join("_")}`;
  }
  return fieldId;
}

function nestedWithRepeatIndex(nested, repeatIndex) {
  let position = nested.length - 1;
  nested.splice(position, 0, repeatIndex);
  return nested;
}

const dynamicLabels = computed(() => {
  const labelsObject = {};
  for (let i in formFields) {
    const formField = formFields[i];
    labelsObject[getVModelId(formField)] = inputLabel(formField.label);
  }
  return labelsObject;
});

function inputLabel(label) {
  let finalLabel = label;
  if (typeof label === "object") {
    const finalLabel = label.default;
    for (let i in label.dependent) {
      const item = label.dependent[i];
      if (dependenciesActive(label)) {
        return item.text;
      }
    }
    return finalLabel;
  }
  return finalLabel;
}

const errorsList = ref([]);

// Watch for changes in formData and trigger validation
watch(
  formData,
  (newValue, oldValue) => {
    validateFormFields(newValue);
  },
  { deep: true }
);

// Function to validate form fields
function validateFormFields(formData) {
  for (const field of formFields) {
    if (["baseline", "id", "building_id", "comments"].includes(field.id))
      continue;
    const fType = field.type;
    const fValue = formData[field.id];
    const fRange = field.range;
    const activeDependencies = dependenciesActive(field);
    if (activeDependencies) {
      if (fValue === null) {
        const errorMessage = `${
          field.label.default ? field.label.default : field.label
        } is required but is empty.`;
        if (!errorsList.value.includes(errorMessage)) {
          errorsList.value.push(errorMessage);
        }
        // Handle validation error for this field
      } else {
        if (field.label) {
          removeError(
            `${
              field.label.default ? field.label.default : field.label
            } is required but is empty.`
          );
        }
      }
      if (
        (fType === "integer" || fType === "real") &&
        (fValue < fRange.min || fValue > fRange.max)
      ) {
        const errorMessage = `${
          field.label.default ? field.label.default : field.label
        } has an invalid value.`;
        if (!errorsList.value.includes(errorMessage)) {
          errorsList.value.push(errorMessage);
        }
        // Handle validation error for this field
      } else {
        if (field.label) {
          removeError(
            `${
              field.label.default ? field.label.default : field.label
            } has an invalid value.`
          );
        }
      }
    }
  }
}

// Function to remove error from errorsList
function removeError(errorMessage) {
  const index = errorsList.value.indexOf(errorMessage);
  if (index !== -1) {
    errorsList.value.splice(index, 1);
  }
}

function hasInvalidValue(field, r) {
  const formDataItem = formData.value[getVModelId(field, r)];
  const integerOrReal = ["integer", "real"].includes(field.type);
  const isNotEmpty = formDataItem !== "" && formDataItem !== null;
  const range = field.range;
  let exceedsMin = false;
  let exceedsMax = false;
  if (range) {
    exceedsMin = range.min !== undefined ? formDataItem < range.min : false;
    exceedsMax = range.max !== undefined ? formDataItem > range.max : false;
  }
  const exceedsRange = exceedsMin || exceedsMax;
  return integerOrReal && isNotEmpty && exceedsRange;
}

// Watchers
// Auto values currently work for only one dependency.
const updatesValueFields = formFields
  .filter((e) => "updates" in e)
  .map((e) => ({ id: e.id, updates: e.updates }));
// https://stackoverflow.com/questions/62729380/vue-watch-outputs-same-oldvalue-and-newvalue
const computedFormData = computed(() => {
  return Object.assign({}, formData.value);
});


const updateFieldsWatcherFunction = (newValue, oldValue, immediateAction = false) => {
  for (let i in updatesValueFields) {
    // parse all fields that update other fields
    const updateField = updatesValueFields[i]; // store the current field in a constant
    if (immediateAction || (!immediateAction && newValue[getVModelId(updateField)] !== oldValue[getVModelId(updateField)])) {
      // if the field has changed value
      const inputType = _getFormFieldType(updateField.id); // get the type of the field that updates because we should
      // handle different types independently, e.g. for multipleChoice we have to find the text value.
      const dependentFields = updateField.updates; // find the dependent fields of the current field, i.e. those
      // that should be updated
      if (inputType === "multipleChoice") {
        // handle different types here
        if (newValue[updateField.id] === null) {
          continue
        }
        const textValue = _getValueOfSelectedField(
            updateField.id,
            newValue[updateField.id]
        );

        const updateFieldFilterValue = _getfilterValueOfSelectField(
            updateField.id,
            newValue[updateField.id]
        );
        _updateDependentFields(
            dependentFields,
            textValue,
            updateFieldFilterValue,
            immediateAction
        );
      }
    }
  }
}

onBeforeMount(() => {
  const hasSets = formFields.some(e => e?.options?.sets !== undefined)
  if (dbObject['id'] !== null) {
    updateFieldsWatcherFunction(dbObject, dbObject.value, true)
  }
});

watch(computedFormData, (newValue, oldValue) => updateFieldsWatcherFunction(newValue, oldValue), { deep: true },);

// Helpers for above method
function _getFormFieldById(fieldId) {
  if (typeof fieldId === "object") {
    fieldId = fieldId.id;
  }
  let dField = null;
  formFields.forEach((f) => {
    if (getVModelId(f) === fieldId) {
      dField = f;
    }
  });
  return dField; // formFields.find(e => getVModelId(e) === fieldId) // find its type
}

function _getFormFieldType(fieldId) {
  return _getFormFieldById(fieldId).type;
}

function _getOptionOfSelectField(fieldId, value) {
  const formField = formFields.find((e) => getVModelId(e) === fieldId); // find its type
  if (formField.options.sets) {
    getFieldToWhichDepend(fieldId);
    let selectedOption = formField.optgroup.find((e) => e.value === value);
    if (selectedOption !== undefined) { //if the previously selected option exist in the new set of options then select it
      return formField.optgroup.find((e) => e.value === value) // e.value === value);
    } else { // if the selection does not exist then return the first option as default
      return formField.optgroup[0]
    }
  } else {
    return formField.options.find((e) => e.value === value);
  }
}

function _getTextValueOfSelectField(fieldId, value) {
  // this repeats the same search as _getFormFieldType(fieldId) but I want the code to be clear
  return _getOptionOfSelectField(fieldId, value).text;
}

function _getValueOfSelectedField(fieldId, value) {
  return _getOptionOfSelectField(fieldId, value).value;
}

function _getfilterValueOfSelectField(fieldId, value) {
  if (_getOptionOfSelectField(fieldId, value).filter !== undefined) {
    return _getOptionOfSelectField(fieldId, value).filter;
  } else return "";
}

function _updateDependentFields(
  dependentFieldsWithRules,
  fieldValue,
  updateFieldFilterValue,
  immediateAction = false
) {
  // dependentFieldsWithRules: array of fields to be updated and their rules
  // fieldValue: the value based on which the dependent fields will be updated
  for (let i in dependentFieldsWithRules) {
    const item = dependentFieldsWithRules[i];
    const dependentField = item;
    const rules = item.rules;
    _updateDependentField(
      dependentField,
      rules,
      fieldValue,
      updateFieldFilterValue,
      immediateAction
    );
  }
}
// Updates a dependent form field based on a set of rules.
//
// This function updates a dependent field in a form based on rules defined in  forms.yml configuration.
// It checks if the provided value or field filter value matches any of the rule conditions and updates the
// dependent field's value accordingly. It handles different field types like checkboxes and locks (disables) the field if specified by the rule.
//
// Params used:
// - *dependentField* [Object] the field that depends on the triggered rule (contains field ID).
// - *rules* [Array] list of rules, where each rule contains conditions such as `includes`, `reset`, `value`, `lock`, etc.
// - *value* [String] the current value used to check against the rules' conditions.
// - *updateFieldFilterValue* [String] optional filter value used in addition to `value` for determining rule applicability.
//
// The function:
// - Iterates through the list of rules.
// - Checks if the `value` or `updateFieldFilterValue` matches any rule conditions.
// - Updates the form field accordingly (e.g., setting values, resetting checkboxes, filtering options).
// - Locks or unlocks the input field if specified by the rule.
//
// @return [Void] Updates formData based on rules and locks the input as necessary.
function _updateDependentField(
  dependentField,
  rules,
  value,
  updateFieldFilterValue,
  immediateAction = false
) {
  // dependentField: a field id
  // rules: the list of rules from YAML
  // value: the value based on which the field will change
  // find which rule is activated:
  let fieldId = dependentField.id;
  let lockedByOtherRule = false;
  for (let i in rules) {
    const rule = rules[i];
    for (let j in rule.includes) {
      const str = rule.includes[j];
      const lowerCaseUpdateFieldFilterValue =
        updateFieldFilterValue !== undefined
          ? updateFieldFilterValue.toLowerCase()
          : "";

      if (
        value.toString().toLowerCase().includes(str.toLowerCase()) ||
        lowerCaseUpdateFieldFilterValue.includes(str.toLowerCase())
      ) {

        _lockInput(dependentField, rule.lock === true); // I added === true to handle cases when undefined.
        lockedByOtherRule = true;

        // Handle checkboxes.
        const fieldType = formFields.find((e) => e.id === fieldId).type;
        if (fieldType === "checkboxes") {
          if (rule.reset) {
            for (let k in formData.value[fieldId]) {
              formData.value[fieldId][k] = false;
            }
          }
        } else {
          // Normal handling.
          // formData.value[dependentField.id] = rule.value;

          //iterate through form data if field includes str then set its value to rule.value
          for (const key in formData.value) {
            if (Object.prototype.hasOwnProperty.call(formData.value, key)) {
              if (key.includes(fieldId)) {
                // || selectedValue.inludes(fieldId)
                if (rule.filter) {
                  formFields.find((field) => field.id === fieldId).optgroup = setOptionsFromSet(fieldId, rule.filter);
                  if (dbObject[dependentField.id]) {
                    formData.value[dependentField.id] = dbObject[dependentField.id]
                  }
                } else {
                  // If it runs onBeforeMounted we only want to lock fields and not to update.
                  // For example, we have a heat pump which sets purpose to null if selected.
                  // This will empty the purpose input on form load, but we don't want that.
                  // However, we want to have locked fields, for example in the case of boiler in edit mode.
                  if(immediateAction) {
                    continue;
                  }
                  formData.value[key] = rule.value;
                }
              }
            }
          }
        }
      } else {
        if(!lockedByOtherRule) {
          _lockInput(dependentField, false)
        }
      }
    }
  }
}
// Updates the options for a form field based on a rule filter.
//
// This function modifies the available options of a specified form field (MultipleChoice) by finding a matching option set based on
// the provided filter criteria. It searches the field's `sets` to find the appropriate option list and updates the field with the new options.
//
// Params used:
// - *fieldId* [String] the ID of the field whose options will be updated.
// - *ruleFilter* [String] the filter used to identify the matching set of options.
//
// The function:
// - Finds the field by its `fieldId` from the list of form fields.
// - Searches for the matching option set based on the `ruleFilter`.
// - Updates the field’s `options` with the corresponding option set.
//
// @return [Void] Updates the options of the specified form field.
function setOptionsFromSet(fieldId, ruleFilter) {
  const fieldToChange = formFields.find((field) => field.id === fieldId);
  const options = fieldToChange.options.sets.find(
    (option) => option.filter === ruleFilter
  );

  if(options === undefined) { // In case no set was find matching the rule. Then the options is an empty array.
    return []
  }

  return options.options;
}
// Finds the form field upon which the specified field depends and updates its options accordingly.
//
// This function looks for the form field that updates the given field (identified by `fieldId`). It searches through the fields
// to locate a field that has `updates`, then iterates through the associated rules to find matching filters. If a match is found,
// it updates the options of the dependent field.
//
// Params used:
// - *fieldId* [String] the ID of the field whose options will be updated based on dependencies.
//
// The function:
// - Searches the `formFields` array to find a field that updates the field with the given `fieldId`.
// - Iterates over the rules of the updating field, filtering for rules that contain `filter`.
// - Retrieves the saved value for the updating field from the database and compares it against the rule filter.
// - If a matching filter is found, it updates the dependent field’s options by calling `setOptionsFromSet`.
//
// @return [Void] Updates the options of the dependent form field.
function getFieldToWhichDepend(fieldId) {
  //fieldId is the field we want to find options for
  // Iterate over formFields and filter for those that have 'updates' to find the field that updates the field with id = fieldId
  formFields.forEach((formField) => {
    if (formField.updates) {
      const relevantRule = formField.updates.find(
        (rule) => rule.id === fieldId
      );
      //after we find the the field to which the fieldId depend to, we itterate through its rules
      if (relevantRule && relevantRule.rules) {
        relevantRule.rules
          .filter((rule) => rule.filter) // Filter rules that contain 'filter' since the other rules contain 'values'
          .forEach((rule) => {
            let valueOfFormFieldSavedInDb = dbObject[formField.id];
            let filterValue = _getfilterValueOfSelectField(
              formField.id,
              valueOfFormFieldSavedInDb
            );

            let isItIncludedResult = isItIncluded(rule, filterValue);

            if (isItIncludedResult) {
              setOptionsFromSet(fieldId, rule.filter);
            }
          });
      }
    }
  });
}

// Checks if any word from a normalized source string exists in the target string.
//
// This function normalizes the `filterValue` by replacing underscores with spaces and then splits it into individual words.
// It checks whether any of these words are included in the `ruleFilter`. If at least one word from the normalized `sourceString`
// is found in the `targetString`, the function returns `true`.
//
// Params used:
// - *filterValue* [String] the string containing words separated by underscores that needs to be normalized and checked.
// - *ruleFilter* [String] the string where the function will search for the words from the normalized `sourceString`.
//
// The function:
// - Replaces all underscores (`_`) in `filterValue` with spaces to create the normalized string.
// - Splits the normalized string into individual words.
// - Iterates over the words from the normalized string.
// - Checks if each word is included in the `ruleFilter`.
// - If any word is found in the `ruleFilter`, it returns `true`.
//
// @return [Boolean] `true` if at least one word from the normalized `filterValue` is found in `ruleFilter`, otherwise `false`.

function isItIncluded(rule, filterValue) {
  return rule.filter.includes(filterValue)
}

function _lockInput(field, lock = true) {
  const inputField = document.getElementById(fullInputName(field));
  if (inputField) {
    inputField.disabled = lock;
  }
  lockedInputs.value[field.id] = lock
}

function clearInput(field) {
  const inputField = document.getElementById(fullInputName(field));
  inputField.value = null;
}

function repeatTimes(field) {
  const repeat = field.repeat;
  if (repeat) {
    const sourceId = field.repeat.source;
    const sourceField = _getFormFieldById(sourceId);
    const times = formData.value[getVModelId(sourceField)];
    if (times > 0) {
      return parseInt(times);
    } else {
      return 0;
    }
  }
  return 1;
}

// ----------------------------

watch(
  formData,
  (newValue, oldValue) => {
    validateFormFields(newValue);
  },
  { deep: true }
);

function isHidden(fieldType) {
  return fieldType === "hidden";
}

function hasErrors(attribute) {
  return (
    dbObject.errors &&
    dbObject.errors[attribute] &&
    dbObject.errors[attribute].length > 0
  );
}

function dependenciesActive(field) {
  const dependsOn = field.dependent;
  let active = [];
  if (dependsOn) {
    for (let d in dependsOn) {
      let included = false;
      const fieldId = getVModelId(dependsOn[d]);
      const options = dependsOn[d].options;
      const reverse = dependsOn[d].reverse || false;
      if (formData.value[fieldId]) {
        // because in some cases during initialization some attributes might be missing from formData
        const formFieldType = _getFormFieldType(fieldId);
        if (formFieldType === "multipleChoice") {
          const dependsOnFieldValue = formData.value[fieldId]
            .toString()
            .toLowerCase();

          const dependsOnFieldFilter = _getfilterValueOfSelectField(
            fieldId,
            formData.value[fieldId]
          ).toLowerCase();
          options.forEach((o) => {
            const lowercaseOption = o.toLowerCase();
            included =
                (included ||
                dependsOnFieldValue.includes(lowercaseOption) ||
              dependsOnFieldFilter.includes(lowercaseOption)) ^ reverse;
          });
        } else if (formFieldType === "checkboxes") {
          included =
            included || options.some((e) => formData.value[fieldId][e]);
        }
      }
      active.push(included);
    }
    return active.every((e) => e);
  }
  return true;
}

function emitEvent(option) {
  if (option.emits) {
    emit(option.emits);
  }
}
</script>

<style scoped>
.group-title {
  margin-bottom: 10px;
  color: #007bff;
}

.collapsible-content {
  margin-left: 20px; /* Adjust as needed */
}

.scoped-form-title {
  border-bottom: 2px solid #313e5b;
  font-size: 18px;
  color: #007bff;
}

label {
  color: darkslategray;
  font-size: 14px;
  font-weight: unset;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 30px 0;
  color: #313e5b;
}

h2 span {
  background:#fff;
  padding:0 10px;
}
</style>
