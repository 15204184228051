import accessRulesForm from "../access_rules/access_rules_form";
import createVueApp from "../../utils/create_vue_app";
import ScenarioForm from "../../vue/scenarios/ScenarioForm.vue";
import DataSourceForm from "../../vue/data_sources/DataSourceForm.vue";
import valid_for_lca from "../../helpers/scenario_validation";
import DistrictBuildingForm from "../../vue/DistrictBuildingForm.vue";
import DistrictScenarioForm from "../../vue/scenarios/DistrictScenarioForm.vue";
import DistrictAssetsForm from "../../vue/scenarios/DistrictAssetsForm.vue";
import LcaBuildingSectorResults from "../../vue/districts/lca_building_sector_results.vue"
import leafletMap from "../../vue/shared/leafletMap.vue";
import ChartLoader from "../../vue/charts/ChartLoader.vue";
import LciComponentTable from "../../vue/lci_components/LciComponentTable.vue";
import LciComponentForm from "../../vue/shared/LciComponentForm.vue";

let currentPath, page;

export default function (path) {
  currentPath = path;
  setPage();
  loadScript();
}
/**
 * Determines the current page context based on the URL path and the presence of specific HTML elements.
 *
 * This function checks the `currentPath` variable and the existence of various form elements
 * on the page to set the appropriate `page` variable. This variable is used to determine the type of
 * functionality or script that should be loaded for the current page.
 *
 * @return {void}
 */

function setPage() {
  if (currentPath.includes("/access_rules")) {
    page = "access_rule_form";
  } else if ($("#lci-component-form").length === 1) {
    page = "lci_component_form";
  } else if ($("#scenario-form").length === 1) {
    page = "scenarios_form";
  } else if ($("#data-source-form").length === 1) {
    page = "data_source_form";
  } else if (currentPath.includes("/lca_analysis")) {
    page = "lca_analysis";

    if (currentPath.includes("/districts")) {
      page = "district_lca_analysis"
    }
  } else if ($("#district-scenario-form").length === 1) {
    page = "district";
  } else if ($("#building-district-form").length === 1) {
    page = "districts_building";
  } else if ($("#district-assets-form").length === 1) {
    page = "district_assets";
  } else if (currentPath.includes("/lci_components")) {
      page = "lci_components";
  } else if ($("form#new_building").length === 1 || $("form[id*=edit_building]")
  ) {
    page = "vue-app-map-picker";
  } else if (currentPath.match(/\/buildings\/[0-9]+$/)) {
    page = "valid_for_lca";
  }
}
/**
 * Loads the appropriate script or form handler based on the current page context.
 *
 * This function uses the `page` variable (which is set by the `setPage()` function)
 * to determine which script or function to execute. Each case corresponds to a specific page type
 * and triggers a function that loads or handles the required components for that page.
 *
 * @return {void}
 */

function loadScript() {
  switch (page) {
    case "access_rule_form":
      accessRulesForm();
      break;
    case "lci_component_form":
      handleForm(LciComponentForm);
      break;
    case "scenarios_form":
      handleForm(ScenarioForm);
      break;
    case "data_source_form":
      handleForm(DataSourceForm);
      break;
    case "lca_analysis":
    case "district_lca_analysis":
      handleLcaAnalysis();
      if (page === "district_lca_analysis") {
        createVueApp("vue-app", LcaBuildingSectorResults)
      }
      break;
    case "valid_for_lca":
      valid_for_lca();
      break;
    case "district":
      handleForm(DistrictScenarioForm);
      break;
    case "district_assets":
      handleForm(DistrictAssetsForm);
      break;
    case "districts_building":
      handleForm(DistrictBuildingForm)
      enableSubmitForm(document.getElementById("building-district-form"))
      break;
    case "vue-app-map-picker":
      handleForm(leafletMap);
      break;
    case "lci_components":
      handleForm(LciComponentTable);
      break;
    default:
      break;
  }
  /**
   * Initializes the form handling process by resetting the URL state, creating a Vue instance, and enabling form submission.
   *
   * This function performs the following:
   * 1. Replaces the current URL state by removing any query parameters.
   * 2. Initializes a Vue app for the specified component.
   * 3. Enables the submit functionality of the form.
   *
   * @param {String} component - The name of the Vue component to be initialized within the Vue app.
   * @param {String} target - The id of the element to mount the Vue app.
   *
   * @return {void}
   */
  function handleForm(component, target="vue-app") {
    clearUrlParams()
    createVueApp(target, component);
    enableSubmitForm();
  }

  /**
   * This method prepares the form's params before submission. It intercepts the form submission, enables disable inputs,
   * sets the `continue` param which is used to notify the backend whether the user will be redirected back to the
   * form or to the resource's show page (e.g. in Scenario form) and, finally, manually submits the form.
   * Disabled inputs are inputs of the AutomaticForm that automatically receive values and then they are locked so that
   * the user cannot edit them (e.g. the "Purpose" field in HVAC) when a boiler is selected.
   *
   * @param {Object} formElement Custom form element to be submitted. Useful for nested forms in Chrome browser.
   * @return {void}
   */
  function enableSubmitForm(formElement=null) {
    const submitButton = $(":submit");
    $(submitButton).on("click", (e) => {
      e.preventDefault();
      enableDisabledInputs();
      setContinueParam($(e.target).data().continue);
      if(formElement !== null) {
        formElement.submit();
      } else {
        $("form").submit();
      }
    });
  }

  /**
   * Handles the lifecycle analysis (LCA) process by updating the URL and initializing Vue chart components.
   *
   * This function:
   * 1. Removes any `commit=Submit` query parameters from the current URL.
   * 2. Replaces the current history state with the updated URL if it has changed.
   * 3. Initializes Vue apps for all elements with the class `vue-chart` using the `ChartLoader` component.
   *
   * @return {void}
   */
  function handleLcaAnalysis() {
    var currentUrl = window.location.href;
    var updatedUrl = currentUrl.replace(/&?commit=Submit/, "");
    if (updatedUrl !== currentUrl) {
      history.replaceState({}, "", updatedUrl);
    }

    const allCharts = document.getElementsByClassName("vue-chart");
    for (let i = 0; i < allCharts.length; i++) {
      createVueApp(allCharts[i].id, ChartLoader);
    }
  }

  /**
   * Enables all disabled input fields, select elements, and options within a form.
   *
   * @return {void}
   */
  function enableDisabledInputs() {
    const disabledInputs = $(
      "input:disabled, select:disabled, option:disabled"
    );
    $(disabledInputs).each(function () {
      $(this).removeAttr("disabled");
    });
  }

  /**
   * Sets the value of the hidden input field with name `continue` to a specified value.
   *
   * This function is used to set a parameter that controls whether the form should continue after submission.
   *
   * @param {String} continueValue - The value to be set in the hidden `continue` input field.
   *
   * @return {void}
   */
  function setContinueParam(continueValue) {
    $("input#continue").val(continueValue);
  }

  /**
   * Removes any parameters from the URL. Useful when we want to use a param to inform the
   * JavaScript code about something, but we don't want it to be visible to the user.
   * Warning: This might cause problems if the user reloads/refreshes the page manually.
  **/
  function clearUrlParams() {
    window.history.replaceState({}, "", window.location.href.split("?")[0]);
  }
}
