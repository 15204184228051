<template>
  <AutomaticFormBuilding
    title="General information"
    :form-fields="props.formFields"
    prefix="building"
    :db-object="dbObject"
    @valid="onValid"
    :isDistrict="true"
    :user_buildings="props.user_buildings"
  ></AutomaticFormBuilding>
  <ul
    class="nav nav-pills mb-3 bg-white p-2 rounded shadow-sm"
    id="pills-tab"
    role="tablist"
  >
    <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
      <a
        class="nav-link"
        :class="{ active: isTabActive(tab.key) }"
        :id="'pills-' + tab.key + '-tab'"
        data-toggle="pill"
        :href="'#pills-' + tab.key"
        role="tab"
        :aria-controls="'pills-' + tab.key"
        :aria-selected="index === 0"
        @click="updateLastSelectedItem(tab.key)"
        >{{ tab.label }}</a
      >
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade"
      :class="{ 'show active': isTabActive(tab.key) }"
      v-for="(tab, index) in tabs"
      :key="index"
      :id="'pills-' + tab.key"
      role="tabpanel"
      :aria-labelledby="'pills-' + tab.key + '-tab'"
    >
      <div class="alert alert-warning p-1 mb-2" role="alert">
        <h3><b>Notice:</b></h3>
        <ul class="m-0 p-1 list-unstyled">
          <li v-for="warning in globalWarnings">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            {{ warning }}
          </li>
          <li v-for="warning in warnings[tab.key]">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            {{ warning }}
          </li>
        </ul>
      </div>
      <NestedAutomaticForm
        :db-objects="tab.dbObjects"
        :form-fields="tab.formFields"
        :isDistrict="true"
        :title="tab.formTitle"
        :prefix="'building[' + tab.prefix + ']'"
        :associations="tab.associations"
        :max-nested-forms="tab.maxNestedForms"
        :start-with="tab.startWith"
        @valid="onValid"
      ></NestedAutomaticForm>
    </div>
  </div>
  <input type="hidden" name="selectedTab" v-model="lastTab" />
</template>

<script setup>
import AutomaticFormBuilding from "./shared/AutomaticFormBuilding.vue";
import NestedAutomaticForm from "./shared/NestedAutomaticForm.vue";

import { computed, ref } from "vue";

const props = defineProps({
  formFields: {
    type: Array,
    required: true,
  },
  dbObject: {
    type: Object,
    required: true,
  },
  hvacFormFields: {
    type: Array,
    required: true,
  },
  hvacDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  insulationFormFields: {
    type: Array,
    required: true,
  },
  insulationDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  dhwFormFields: {
    type: Array,
    required: true,
  },
  dhwDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  windTurbineFormFields: {
    type: Array,
    required: true,
  },
  windTurbineDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  batteryFormFields: {
    type: Array,
    required: true,
  },
  batteryDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  auxiliaryFormFields: {
    type: Array,
    required: true,
  },
  auxiliaryDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  photovoltaicFormFields: {
    type: Array,
    required: true,
  },
  photovoltaicDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  connectionFormFields: {
    type: Array,
    required: true,
  },
  connectionDbObjects: {
    type: Array,
    required: false,
    default: [],
  },
  currentTab: {
    type: String,
    required: false,
    default: null,
  },
  isDistrict: {
    type: Boolean,
  },
  user_buildings: {
    type: Object,
  },
});

const tabs = [
  {
    key: "hvac",
    label: "HVAC",
    formTitle: "HVAC component",
    prefix: "hvacs_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.hvacDbObjects,
    formFields: props.hvacFormFields,
    associations: undefined,
    maxNestedForms: undefined,
    startWith: undefined,
  },
  {
    key: "insulation",
    label: "Insulation / Glazing",
    formTitle: "Insulation component",
    prefix: "insulations_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.insulationDbObjects,
    formFields: props.insulationFormFields,
    associations: undefined,
    maxNestedForms: undefined,
    startWith: undefined,
  },
  {
    key: "dhw",
    label: "DHW",
    formTitle: "DHW component",
    prefix: "dhws_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.dhwDbObjects,
    formFields: props.dhwFormFields,
    associations: props.hvacDbObjects,
    maxNestedForms: undefined,
    startWith: undefined,
  },
  {
    key: "photovoltaic",
    label: "Photovoltaic",
    formTitle: "Photovoltaic component",
    prefix: "photovoltaics_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.photovoltaicDbObjects,
    formFields: props.photovoltaicFormFields,
    associations: undefined,
    maxNestedForms: undefined,
    startWith: undefined,
  },
  {
    key: "windTurbine",
    label: "Wind turbine",
    formTitle: "Wind turbine component",
    prefix: "wind_turbines_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.windTurbineDbObjects,
    formFields: props.windTurbineFormFields,
    associations: undefined,
    maxNestedForms: undefined,
    startWith: undefined,
  },
  {
    key: "battery",
    label: "Energy storage",
    formTitle: "Energy storage component",
    prefix: "batteries_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.batteryDbObjects,
    formFields: props.batteryFormFields,
    associations: undefined,
    maxNestedForms: undefined,
    startWith: undefined,
  },
  {
    key: "auxiliary",
    label: "Auxiliaries",
    formTitle: "Auxiliary component",
    prefix: "auxiliaries_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.auxiliaryDbObjects,
    formFields: props.auxiliaryFormFields,
    associations: undefined,
    maxNestedForms: undefined,
    startWith: undefined
  },
  {
    key: "connection",
    label: "Connection",
    formTitle: "Grid connections",
    prefix: "connections_attributes",
    isDistrict: props.isDistrict,
    dbObjects: props.connectionDbObjects,
    formFields: props.connectionFormFields,
    associations: undefined,
    maxNestedForms: 1,
    startWith: 1,
  },
];

const globalWarnings = [
  "Installation year of components must be less than or equal to the year of the first timestamp of the timeseries.",
  "Fields marked with the INTEMA (blue) logo are necessary for the Intema analysis.",
];

const warnings = {
  connection: [
    "The periods number and durations in import and export of electricity must be the same.",
  ],
  investment: [
    "The investment form is filled with default values, please make any necessary changes considering your scenario.",
  ],
};

const isValid = ref(false);

function onValid(e) {
  isValid.value = isValid.value && e;
}

const lastTab = ref(
  props.currentTab ?? sessionStorage.getItem("lastTab") ?? "hvac"
);

function updateLastSelectedItem(e) {
  // Adds an entry to the sessionStorage so that when user clicks "Save and continue" it starts from where it was.
  sessionStorage.setItem("lastTab", e);
  lastTab.value = e;
}

function isTabActive(tab) {
  return lastTab.value === tab;
}
</script>

<style scoped></style>
